
import fingerprint from '~/mixins/fingerprint'
import ReCaptcha from '~/mixins/recaptcha'
import interRegister from '~/mixins/pages/register/interRegister'
import { PLAYER_REGEX } from '~/utils/constants'

export default {
    name: 'RegisterModal',
    mixins: [fingerprint, ReCaptcha, interRegister],
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,
            isConditional: false,
        }
    },
    computed: {
        modal: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            },
        },
    },
    watch: {
        acceptCondition(value) {
            if (value) {
                this.isConditional = false
            } else {
                this.isConditional = true
            }
        },
        operationSetting(value) {
            this.reg_flow = value.reg_flow
            this.playerRegistrationType = value.player_reg_id
            this.bankNameType = value.bank_name_type
        },
        bankOptionSelection(value) {
            if (value === 2) {
                this.form.bank_currency = this.$cookie.get('currency')
            }
        },
    },
    created() {
        if (this.supportCountries.length) {
            this.country = this.supportCountries[0]?.country_code
            this.form.currency = this.supportCountries[0]?.default_currency
        }
    },
    async mounted() {
        const countryCode = this.$cookie.get('country_code')
        await this.$store.dispatch('banks/banks')
        await this.onOperationSetting(this.$cookie.get('currency'))
        this.form.reg_fingerprint = await this.visitorId()
        this.form = {
            ...this.form,
            country_code: countryCode,
        }
        this.countryChange(countryCode)
        const globalReg = this.$cookie?.get('global_reg')
        this.globalReg = globalReg
    },
    methods: {
        onCloseModal() {
            this.modal = false
            this.$store.commit('themes/settings/setDisplayRegisterModal', false)
            this.$emit('onCloseModal')
            this.$emit('close')
        },
        async register() {
            if (this.acceptCondition) {
                if (this.$refs.form.validate()) {
                    if (this.form.player_id) {
                        if (!PLAYER_REGEX.test(this.form.player_id)) {
                            return
                        }
                    }
                    try {
                        this.loading = true
                        this.form.recaptchaToken = await this.getRecaptchaToken(
                            'register'
                        )

                        this.form.reg_flow = this.reg_flow
                        if (this.playerRegistrationType !== 1) {
                            delete this.form.player_id
                        }
                        const { redirect, res } = await this.$store.dispatch(
                            'auth/registration',
                            {
                                ...this.form,
                                bankOptionSelection: this.bankOptionSelection,
                            }
                        )
                        await this.$store.dispatch(
                            'settings/supportCountryAndLanguage',
                            { fetch: true }
                        )
                        if (res) {
                            this.modal = false
                            this.$emit('onCloseModal')
                            this.$store.commit(
                                'themes/settings/setDisplayRegisterModal',
                                false
                            )
                            // Delay WebSocket initialization to ensure previous operations are fully completed
                            setTimeout(async () => {
                                await this.initSocketTheme()
                            }, 1000)
                            this.$router.push(redirect)
                        } else {
                            this.resetRecaptcha()
                        }
                        this.loading = false
                    } catch (error) {
                        this.loading = false
                    }
                }
            } else {
                this.isConditional = true
                this.$toast.error(this.tl('pleaseCheckCheckCondition'))
            }
        },
    },
}
